import './App.css';
import React, { useState, useRef } from "react";
import { Routes, Route,Router, useParams } from "react-router-dom"

import Agendamento from './pages/agendamento'
import Home from './pages/Home'
import Posts from './pages/Posts'
import Admin from './pages/Admin'
import Team from './pages/Team'
import Agendados from './pages/Agendados'
import Login from './pages/Login'
import Cadastrar from './pages/cadastrar'
import Register from './pages/RegisterUser'
import Setting from './pages/menu-consulta'
import CadastrarConsulta from './pages/menu-consulta/cadastrar-menu-consulta';
import EditarConsulta from './pages/menu-consulta/editar-menu-consulta';
import Mensagem from './pages/Home/mensagem';
import NotFound from "./pages/NotFound/NotFound";
import Sobre from "./pages/Sobre"
import Article from './pages/Article/Article';
import { useNavigate } from 'react-router-dom'



function App() {
  const [showModal, setShowModal] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const navigate = useNavigate()
  const goHome= () => { navigate("/") }
 const toggleModalLogin = () => {
   if(showModalLogin==false){
    goHome()
   }   
    
  };
  const toggleModalCadastro = () => {
   
     goHome()
    
   };
  return (
    <>
  
      <Routes>
      <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Home/>}/>
        <Route path='/agendamento/' element={ <Agendamento /> } />
        <Route path='/login/' element={<Login
        toggleModalLogin={toggleModalLogin}/>} />
        <Route path='/cadastrar/' element={<Cadastrar toggleModalCadastro={toggleModalCadastro}/>}/>
        <Route path='/register/' element={<Register toggleModalCadastro={toggleModalCadastro}/>}/>
        <Route path='/posts/' element={<Posts/>}/>
        <Route path='/admin/' element={<Admin/>}/>
        <Route path='/team/' element={<Team/>}/>
        <Route path='/sobre/' element={<Sobre/>}/>
        <Route path='/agendados/' element={<Agendados/>}/>
        <Route path='/menu-consulta/' element={<Setting/>}/>
        <Route path='/cadastrar-menu-consulta/' element={<CadastrarConsulta/>}/>
  <Route path='/editar-menu-consulta/:id' element={<EditarConsulta/>}/>
  <Route path='/mensagem' element={<Mensagem/>}/>
  <Route path='/article/:slug' element = {<Article/>}/>
      </Routes>
      
    </>
  );
}

export default App;
