import React from 'react'
import NavAdmin from '../../composant/NavAdmin'
import SidebarAdmin from '../../composant/sideBarAdmin'
import { useEffect, useState, useRef } from "react"
import './../Admin/style.css'
import './styles.css'
import { Link, useParams } from 'react-router-dom';

const Index = () => {
	const [dataCardapio, setDataCardapio] = useState([]);
  const [envioStatus, setEnvioStatus]= useState({
    type: '',
    mensagem:''
  })
	const [menuConsulta, setMenuConsulta] = useState([]);
	const myRef = useRef();
	const UrlBase = ' http://localhost/ngsAdvogada/';
	const getMenuConsulta = async () => {
		fetch(UrlBase + 'menu_consulta.php')
			.then(response => response.json())
			.then(data => {
				console.log(data.length)

				setMenuConsulta(data);
			})

	}

	const deletarMenuConsulta = async (id) =>{

		await fetch("http://localhost/ngsAdvogada/deletar_menu_consulta.php?id="+ id)
				.then((response)=> response.json())
				.then((responseJson)=>{
				  if(responseJson.erro) {
					setEnvioStatus({
					  type: 'erro',
					  mensagem:responseJson.mensagem
					});
					
				  }else{
					setEnvioStatus({
					  type: 'sucess',
					  mensagem:responseJson.mensagem
					});
					getMenuConsulta(); 
				  }
					console.log(responseJson);                
				}).catch(()=>{
				  setEnvioStatus({
					type: 'erro',
					mensagem:"Erro: Produto não Excluido tente mais tarde "
	
				  });
				  
				});
	  };
	

	useEffect(() => {
		getMenuConsulta();

	}, []);
console.log(envioStatus)
	return (
		<>
			<section id="content">
				<NavAdmin />
				<SidebarAdmin />
				<main>
					<div class="head-title">
						<div class="left">
							<h1>Dashboard</h1>
							<ul class="breadcrumb">
								<li>
									<a href="#">Dashboard</a>
								</li>
								<li><i class='bx bx-chevron-right' ></i></li>
								<li>
									<a class="active" href="#">Home</a>
								</li>
							</ul>
						</div>

						<a href="/cadastrar-menu-consulta" class="btn-download">
							<span class="text">Adicionar novo</span>
						</a>
					</div>

					<div class="table-data">

						<div class="order">
							<div class="head">
								<h3>Agendamento de hoje </h3>
								<i class='bx bx-search' ></i>
								<i class='bx bx-filter' ></i>
							</div>
							<table>
								<thead>
									<tr>
										<th>Titúlo</th>
										<th>Valor por consulta</th>
										<th>Ação</th>
									</tr>
								</thead>
								<tbody>
									{(Object.values(menuConsulta).map(consul =>
										<tr>
											<td>
												{consul.titre}
											</td>
											<td>{consul.tarif}</td>
											<td >
												{consul.acao}
												<span> <a href={"/editar-menu-consulta/"+consul.id}>Editar</a> </span>
												<span> <button onClick={()=>deletarMenuConsulta(consul.id)}>Excluir</button> </span>
											</td>
										</tr>
									))}

								</tbody>
							</table>
						</div>
					</div>
				</main>
			</section>
		</>
	)
}

export default Index
