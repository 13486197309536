import React from 'react'
import { useEffect, useState, useRef  } from "react"
import logo from './../../assets/images/POSTS_FUNDOS_05.png'
import './style.css'
import Login from './../../pages/Login'
import SidebarProfile from '../SidebarProfile/SidebarPorfile'
import { Link } from 'react-router-dom'
import './accueil.css'
import './animations.css'
import { useNavigate } from 'react-router-dom'

function Index(props) {
  const [showModal, setShowModal] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const modalRef = useRef(null);
  
  const toggleModal = () => {
      setShowModal(!showModal);
      console.log(showModal)
    };

  const toggleModalLogin = () => {
   
    setShowModalLogin(!showModalLogin)
    
    
  };
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const toggleModalCadastro = () => {
    if(showModalCadastro==false){
     goHome()
    }   
   };
  const navigate = useNavigate()
  const goHome= () => { navigate("/") }
  const isLoggedIn = localStorage.getItem('isLoggedIn')
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn')
    goHome()
  }
  
  

  
  return (
    <>
     

      <div className='nav-principal-home'>
      
        <img src={logo} width={250} alt="ngs-advogada" />
        <div className='btn-menu'onClick={toggleModal}>
        <button className='mobile-menu' onClick={props.func} >
          <i class='bx bx-menu' ></i>
        </button>
        <span class="nav-link" >Menu</span>
        </div>
        <form action="#">
          <div class="form-input">
            <input type="search" placeholder="pesquisar no ngs advogada..."
              name="pesquisa"
               />
            <button type="submit" class="search-btn"><i class='bx bx-search' ></i></button>
          </div>
        </form>
       
        
       
        
          {isLoggedIn ? (<div className='profile'>
        
        <span>{localStorage.getItem('nomeUsuario')}</span>
        <span onClick={handleLogout}>Sair</span>
        </div>) : (<div  class="profile" >
          <span onClick={toggleModalLogin}> Entrar </span>  
          <span> <Link to = '/register'>Cadastrar</Link> </span>
           </div>)}
          
        
      </div> 

     
<div>{showModal&& <SidebarProfile toggleModal={toggleModal} showModal={showModal} /> }</div>
{showModalLogin && <Login toggleModalLogin={toggleModalLogin} showModalLogin ={showModalLogin} />}
    </>

  )
}

export default Index
