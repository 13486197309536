import React , {useState,useEffect,useRef}from 'react'
import NavAdmin from '../../composant/NavAdmin'
import SidebarAdmin from '../../composant/sideBarAdmin'
import './../Admin/style.css'

const Index = () => {
	const [agendados, setAgendados] = useState([]);
	const [agendadosMes, setAgendadosMes] = useState([]);
	const myRef = useRef();
	const UrlBase = ' http://localhost/ngsAdvogada/';
	const getAgendados = async () => {
		fetch(UrlBase + 'agendados_hoje.php')
			.then(response => response.json())
			.then(data => {
				console.log(data.length)

				setAgendados(data);
			})

	}
	const getAgendadosMes = async () => {
		fetch(UrlBase + 'agendados_mes.php')
			.then(response => response.json())
			.then(data => {
				console.log(data.length)

				setAgendadosMes(data);
			})

	}

useEffect(() => {
	getAgendados();
	getAgendadosMes();

	}, []);
  return (
    <>
      <section id="content">
      <NavAdmin/>
      <SidebarAdmin/>
      <main>
			<div class="head-title">
				<div class="left">
					<h1>Dashboard</h1>
					<ul class="breadcrumb">
						<li>
							<a href="#">Dashboard</a>
						</li>
						<li><i class='bx bx-chevron-right' ></i></li>
						<li>
							<a class="active" href="#">Home</a>
						</li>
					</ul>
				</div>
				<a href="#" class="btn-download">
					<i class='bx bxs-cloud-download' ></i>
					<span class="text">Download PDF</span>
				</a>
			</div>

			<div class="table-data">

                    <div class="order">
					<div class="head">
						<h3>Agendamento de hoje </h3>
						<i class='bx bx-search' ></i>
						<i class='bx bx-filter' ></i>
					</div>
					<table>
						<thead>
							<tr>
								<th>Cliente</th>
								<th>Data de agendamento</th>
								<th>Serviço</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
						{(Object.values(agendados).map(agenda =>
							<tr>
							
								<td>
									<img src="img/people.png"/>
									<p>{agenda.id_usuario}</p>
								</td>
								<td>{agenda.data_agendado} : {agenda.hora_agendado}</td>
								<td>{agenda.consulta} </td>
								<td><span class="status completed">Completed</span></td>
							</tr>
							))}
							
						</tbody>
					</table>
				</div>
                </div>

                <div class="table-data">

                    <div class="order">
					<div class="head">
						<h3>Agendamento deste Mês </h3>
						<i class='bx bx-search' ></i>
						<i class='bx bx-filter' ></i>
					</div>
					<table>
						<thead>
							<tr>
								<th>Cliente</th>
								<th>Data de agendamento</th>
								<th>Serviço</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
						{(Object.values(agendadosMes).map(agendaMes =>
							<tr>
							
								<td>
									<img src="img/people.png"/>
									<p>{agendaMes.id_usuario}</p>
								</td>
								<td>{agendaMes.data_agendado} : {agendaMes.hora_agendado}</td>
								<td>{agendaMes.consulta} </td>
								<td><span class="status completed">Completed</span></td>
							</tr>
							))}
							
							
						</tbody>
					</table>
				</div>
                </div>
		</main>
                </section>
    </>
  )
}

export default Index
