import React, { useRef, useEffect, useState } from 'react'
import './style.css'
import logo from './../../assets/images/logo_sem_fundo.png'
import api from './../../config/configApi'
import { useNavigate } from 'react-router-dom'

const Index = ({ toggleModalCadastro }) => {
  const refTel = useRef();
  const [statusEnvio, setEnvioStatus] = useState({
    type: '',
    mensagem: ''
  })
  const [stateInputTelefone, setStateInputTelefone] = useState();
  const [stateInputCPF, setStateInputCPF] = useState();
  const [nomeFamiliar, setNomeFamiliar] = useState();
  const [emailFamiliar, setEmailFamiliar] = useState();
  const navigate = useNavigate()
  const goLogin = () => { navigate("/login") }
  const valorTelefone = e => {
    let textoAjustado;
    textoAjustado = e.replace(/(\d{2})(\d{5})(\d{4})/,
      function (regex, arg1, arg2, arg3) {
        return arg1 + ' ' + arg2 + ' ' + arg3;
      });
    e = textoAjustado;

    setStateInputTelefone(e)
    console.log(e)

  }
  const valorCPF = e => {

    let CPFAjustado;

    CPFAjustado = e.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
      function (regex, arg1, arg2, arg3, arg4) {
        return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4;
      });
    e = CPFAjustado;

    setStateInputCPF(e)
    console.log(e)

  }


  const addTodo = async e => {
    e.preventDefault();
    const form = {
      Telefone: stateInputTelefone,
      CPF: stateInputCPF,
      nomeFamiliar: nomeFamiliar,
      emailFamiliar: emailFamiliar,

    }

    console.log(form)
    const headers = {
      'headers': {
        'Content-Type': 'application/json'
      }
    }
    await api.post("/addClientes.php", form)
      .then((response) => {
        console.log(response.data)
        console.log(response.data.codigo)

        if (response.data.codigo!=200) {
          setEnvioStatus({
            type: 'erro',
            mensagem: response.data.mensagem
          });

        } else {
          setEnvioStatus({
            type: 'sucess',
            mensagem: response.data.mensagem
          });
          goLogin();
        }

      }).catch(() => {
        setEnvioStatus({
          type: 'erro',
          mensagem: "Erro: Produto não apagado com sucesso tente mais tarde "

        });

      })

  }
  return (
    <>
      <div class="boxLogin-cadastro">
        <button onClick={toggleModalCadastro} className='close-btn'>x</button>



        <div class="containerInputs">
          <div class="containerLogo">

            <div class="cercle">
              <img src={logo} alt="logo" />
            </div>
          </div>
          {statusEnvio.type==='erro' ?<div className='AlertDanger' >{statusEnvio.mensagem}</div> :""}
                    {statusEnvio.type=== 'sucess' ? <div className='AlertSucess'>{statusEnvio.mensagem}</div> :""}
          <form onSubmit={e => addTodo(e)}>
            <fieldset>
              <label for="prenom" class="labelEmail">Nome Completo</label>
              <input type="text" class="emailInp" name="nome" value={nomeFamiliar}
                onChange={e => setNomeFamiliar(e.target.value)} />
              <div class="flex2Inputs">
                <div className='flexRightInp'>
                  <label for="prenom" class="labelEmail">CPF</label>
                  <input type="text" name="cpf" maxlength="11"
                    placeholder="000.000.000-00" class="flexInp" value={stateInputCPF}
                    onChange={e => valorCPF(e.target.value)} />
                </div>
                <div class="flexLeftInp">
                  <label for="prenom" class="labelEmail">Telefone/WhatsApp</label>
                  <input type="text" id='num' name="telefone" maxlength="11"
                    placeholder="00 00000 0000" pattern="[0-9\s]+" value={stateInputTelefone}
                    onChange={e => valorTelefone(e.target.value)} />
                </div>
              </div>


              <label for="prenom" class="labelEmail">E-mail</label>
              <input type="email" name="email" value={emailFamiliar}
                onChange={e => setEmailFamiliar(e.target.value)} />



            </fieldset>

            <button className='btn-cadastrar' type='submit'>Cadastrar</button>
          </form>

          <p class="txtFin">Já  cadastrado ?
            <a href="./login">
              Entrar
            </a>
          </p>
        </div>

      </div>

    </>
  )
}

export default Index
