import React, { useState,useRef, useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./styles.css"
import api from '../../config/configApi'
import axios from 'axios';

const Index = ({menuConsulta}) => {
  const [startDate, setStartDate] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const horaSelecionada =useRef(null)
  const [consulta, setConsulta] = useState([]);
 
  const isDisabled = startDate === null || isChecked=== undefined;

  const onSubmit = async e => {
    e.preventDefault();
            const form ={
              consulta: consulta,
                id_usuario: 15,
                data_agendado: startDate,
                hora_agendado: isChecked
          }
        
          const headers = {
              'headers': {
                'Content-Type': 'application/json'
              }
            }
          await api.post("/add_agendamento.php",form)
          .then((response)=>{
            console.log(form)
              console.log(response.data)
              
          }).catch((err)=>{
              
          })
  }

  return (
    <>
      <div className="agenda">
        <h1>Agendar sua Consulta</h1>

        <form action="">
          <div className="select-">
        <label htmlFor="">Serviçõs Juridicos</label>
        <select name='acao' value = {consulta} onChange={e=>setConsulta(e.target.value)}>
        {(Object.values(menuConsulta).map(consul =>
            <option value={consul.titre}>{consul.titre}</option>
            ))}
        </select>
        </div>
        <div className="contenainer-data">
          <h3>Escolha uma data disponível:</h3>
        <input type="date" value ={startDate} onChange={e=>setStartDate(e.target.value)}/>
        </div>
        <div className="horas">
        <h3>Escolha uma hora disponível: </h3>
        <div className="container-horas">
          <div className='container'>
  <label >8:30
  <input  type="radio" value={isChecked}  name="radio" onChange={e=>setIsChecked('8:30')}  />
</label>
</div>
<div className='container'>
<label >9:30
  <input  type="radio"  value={isChecked} name="radio"onChange={e=>setIsChecked('9:30')}/>
</label>
</div>
<div className='container'>
<label >10:30
  <input type="radio" name="radio" value={isChecked} onChange={e=>setIsChecked('10:30')}/>
  
</label>
</div>

<div className='container'>
<label >11:30
  <input type="radio" name="radio" value={isChecked} onChange={e=>setIsChecked('11:30')}/>
  
</label>
</div>
<div className='container'>
<label >12:30
  <input type="radio" name="radio" value={isChecked} onChange={e=>setIsChecked('12:30')}/>
  
</label>
</div>
<div className='container'>
<label >13:30
  <input type="radio" name="radio" value={isChecked} onChange={e=>setIsChecked('13:30')}/>
  
</label>
</div>
<div className='container'>
<label >14:30
  <input type="radio" name="radio"  value={isChecked} onChange={e=>setIsChecked('14:30')}/>
  
</label>
</div>
<div className='container'>
<label >15:30
  <input type="radio" name="radio" value={isChecked} onChange={e=>setIsChecked('15:30')} />
  
</label>
</div>
<div className='container'>
<label >16:30
  <input type="radio" name="radio"  value={isChecked} onChange={e=>setIsChecked('16:30')}/>
  
</label>
</div>
<div className='container'>
<label >17:30
  <input type="radio" name="radio" value={isChecked}  onChange={e=>setIsChecked('17:30')}/>
</label>
</div>
</div>
        </div>
        <button type="submit" className="agenda-btn" disabled={isDisabled} onClick={onSubmit}>confirmar</button>
        </form>
</div>
    </>
  )
}

export default Index
