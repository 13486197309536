import React from 'react'
import NavAdmin from '../../composant/NavAdmin'
import SidebarAdmin from '../../composant/sideBarAdmin'
import { useEffect, useState, useRef } from "react"
import './../Admin/style.css'
import './styles.css'
import { Link, useParams } from 'react-router-dom';

const Index = () => {
	const [dataCardapio, setDataCardapio] = useState([]);
  const [envioStatus, setEnvioStatus]= useState({
    type: '',
    mensagem:''
  })
	const [mensagens, setMensagens] = useState([]);
	const myRef = useRef();
	const UrlBase = ' http://localhost/ngsAdvogada/';
	const getMensagens = async () => {
		fetch(UrlBase + 'mensagens.php')
			.then(response => response.json())
			.then(data => {
				console.log(data.length)

				setMensagens(data);
			})

	}

	const deletarMensagens = async (id) =>{

		await fetch("http://localhost/ngsAdvogada/deletar_menu_consulta.php?id="+ id)
				.then((response)=> response.json())
				.then((responseJson)=>{
				  if(responseJson.erro) {
					setEnvioStatus({
					  type: 'erro',
					  mensagem:responseJson.mensagem
					});
					
				  }else{
					setEnvioStatus({
					  type: 'sucess',
					  mensagem:responseJson.mensagem
					});
					getMensagens(); 
				  }
					console.log(responseJson);                
				}).catch(()=>{
				  setEnvioStatus({
					type: 'erro',
					mensagem:"Erro: Produto não Excluido tente mais tarde "
	
				  });
				  
				});
	  };
	

	useEffect(() => {
		getMensagens();

	}, []);
console.log(envioStatus)
	return (
		<>
			<section id="content">
				<NavAdmin />
				<SidebarAdmin />
				<main>
					<div class="head-title">
						<div class="left">
							<h1>Dashboard</h1>
							<ul class="breadcrumb">
								<li>
									<a href="#">Dashboard</a>
								</li>
								<li><i class='bx bx-chevron-right' ></i></li>
								<li>
									<a class="active" href="#">Home</a>
								</li>
							</ul>
						</div>

					</div>

					<div class="table-data">

						<div class="order">
							<div class="head">
								<h3>Mensagens </h3>
								<i class='bx bx-search' ></i>
								<i class='bx bx-filter' ></i>
							</div>
							<table>
								<thead>
									<tr>
										<th>Autor</th>
										<th>e_mail</th>
										<th>Telefone</th>
										<th>Motivo</th>
										<th>Mensagem</th>
										<th>Enviado em </th>
										<th>Ação</th>
									</tr>
								</thead>
								<tbody>
									{(Object.values(mensagens).map(consul =>
										<tr>
											<td>{consul.nome} </td>
											<td>{consul.email}</td>
											<td>{consul.telefone}</td>
											<td>{consul.motivo}</td>
											<td>{consul.mensagem}</td>
											<td>{consul.dateCreate}</td>
											<td >
												
												<span> Responder</span>
												<span>Excluir </span>
											</td>
										</tr>
									))}

								</tbody>
							</table>
						</div>
					</div>
				</main>
			</section>
		</>
	)
}

export default Index
