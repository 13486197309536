import {createStore,applyMiddleware, CombineReducers, combineReducers}from 'redux';
import articleReducer from './articles/articleReducer'
import thunk from 'redux-thunk'
import { createStoreHook } from 'react-redux';

const rootReducer = combineReducers({
    articleReducer
})

const store = createStore(rootReducer,applyMiddleware(thunk))

export default store