import React, {useState,useEffect,useRef} from 'react'
import './styles.css'
import Calendar from '../../composant/DatePicker'
import MenuNav from '../../composant/NavBarAdmin'


const Index = () => {
  const [menuConsulta, setMenuConsulta] = useState([]);
	const myRef = useRef();
	const UrlBase = ' https://ngs.adv.br/';
	const getMenuConsulta = async () => {
		fetch(UrlBase + 'menu_consulta.php')
			.then(response => response.json())
			.then(data => {
				console.log(data.length)

				setMenuConsulta(data);
			})

	}

useEffect(() => {
		getMenuConsulta();

	}, []);
  return (
    <>
     <section id="content-home">
        <MenuNav />
      </section>
      
    <div className='agenda'>
      <Calendar menuConsulta={menuConsulta}/>
    </div>
    </>
  )
}

export default Index