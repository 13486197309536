import React from 'react'
import { useLocation } from 'react-router-dom'

export default function Article() {
    const location = useLocation()
  return (
    <div>
      <h2> Sua receita : {location.state.title}</h2>
      <p>Ingrendient</p>
      <p>{location.state.body}</p>
    </div>
  )
}
