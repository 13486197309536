import React from 'react';

import App from './App';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store'

ReactDOM.render(
  <BrowserRouter basename= {process.env.PUBLIC_URL}>
    <Provider store={store}>
    <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
