import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './style.css'
import logo from './../../assets/images/POSTS_FUNDOS_05.png'
import api from './../../config/configApi'


const Index = ({ toggleModalLogin, showModalLogin }) => {

    /*logique login */
    const [usuario, setUsuario] = useState([

        { cpf: '' },
        { Telefone: '' },
    ]);

    const [statusLogin, setStatusLogin] = useState({
        codigo: '',
        type: '',
        mensagem: ''
    })
    const [stateInputTelefone, setStateInputTelefone] = useState();
    const [stateInputCPF, setStateInputCPF] = useState();
    const [isLogin, setIsLogin] = useState(false);
    const [isLoggedIn, setisLoggedIn] = useState(null);

    const valorTelefone = e => {
        let textoAjustado;
        textoAjustado = e.replace(/(\d{2})(\d{5})(\d{4})/,
            function (regex, arg1, arg2, arg3) {
                return arg1 + ' ' + arg2 + ' ' + arg3;
            });
        e = textoAjustado;

        setStateInputTelefone(e)
        console.log(e)

    }
    const valorCPF = e => {

        let CPFAjustado;

        CPFAjustado = e.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
            function (regex, arg1, arg2, arg3, arg4) {
                return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4;
            });
        e = CPFAjustado;

        setStateInputCPF(e)
        console.log(e)

    }
    const navigate = useNavigate()
    const goRegister = () => { navigate("/register") }
    const goHome = () => { navigate("/") }

    const addTodo = async e => {
        e.preventDefault();

        const form = {
            cpf: stateInputCPF,
            Telefone: stateInputTelefone
        }

        console.log(form)
        const headers = {
            'headers': {
                'Content-Type': 'application/json'
            }
        }
        await api.post("/loginUser.php", form)
            .then((response) => {
                console.log(response.data)
                if (response.data.erro) {
                    setStatusLogin({
                        codigo: 400,
                        type: "error",
                        mensagem: response.data.mensagem
                    })
                }
                else {
                    setStatusLogin({
                        codigo: 200,
                        type: "success",
                        mensagem: response.data.mensagem
                    })

                    setIsLogin(true)
                    localStorage.setItem('isLoggedIn', true)
                    localStorage.setItem('idUser', response.data.id_usuario)
                    localStorage.setItem('nomeUsuario', response.data.nome)

                    setisLoggedIn(true)
                    if (showModalLogin == true) {
                        toggleModalLogin()
                    }
                    else { goHome() }

                }

            }).catch(() => {
                setStatusLogin({
                    codigo: 400,
                    type: "error",
                    mensagem: "usuario não cadastrado, tente mais tarde! "
                })

            });
    }
    console.log(showModalLogin)
    return (
        <>
            <div className='container-login'>

                <div class="boxLogin">
                    <button onClick={toggleModalLogin} className='close-btn'>x</button>
                    <div class="containerLogo">

                        <div class="cercle">
                            <img src={logo} alt="logo" />
                        </div>
                    </div>

                    <div class="containerInputs">

                        <form onSubmit={e => addTodo(e)}>
                            <input type="text" name="cpf" maxlength="11"
                                placeholder="CPF" class="flexInp" value={stateInputCPF}
                                onChange={e => valorCPF(e.target.value)} />
                            <input type="text" id='num' name="telefone" maxlength="13"
                                placeholder="Telefone" pattern="[0-9\s]+" value={stateInputTelefone}
                                onChange={e => valorTelefone(e.target.value)} />
                            <button className='btn-cadastrar' type='submit'>Entrar</button>

                        </form>

                        <p class="txtFin">Não cadastrado ?
                            <a href="./register">
                                Cadastre-se
                            </a>
                        </p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Index
