
import React, { useEffect, useState } from 'react';

import Forms from '../../composant/Forms/Forms'
import NavBarAdmin from './../../composant/NavBarAdmin'

import MenuNav from './../../composant/NavBarAdmin'
import NavAdmin from './../../composant/NavAdmin'
import SidebarAdmin from './../../composant/sideBarAdmin'

import { useDropzone } from 'react-dropzone';


const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


export default function Index(props) {

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));

  }, []);

  const imageName = files.map((item) => item.name);

  const ContainerUpload = <div className="container">
  <div {...getRootProps({className: 'dropzone'})}>
    <input {...getInputProps()} />
    <p>Solte arquivos aqui para enviar, ou Selecionar arquivos</p>
  </div>
  <div style={thumbsContainer}>
    {thumbs}
  </div>
</div>
  return (
    <>
      <section id="content">
     
      <NavAdmin/>
      <SidebarAdmin/>

        <Forms images={files} upload={ContainerUpload} />

      </section>
    </>
  )
}
