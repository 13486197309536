export const MenuData = [
    
    {
        title: "Área do Cliente",
        url: "../perfil/"+ localStorage.getItem('idUser'),
        cName : 'nav-links',
        icone : "fa-solid fa-user"
    },
    {
        title: "Agendar Consulta ",
        url: "/agendamento",
        cName : 'nav-links',
        icone:"fa-solid fa-calendar-days"
    },
    {
        title: "Sobre a Dra. Nathalie Guimarães ",
        url: "/",
        cName : 'nav-links',
        icone :"fa-solid fa-circle-info" 
    },
    {
        title: "Atuação",
        url: "/",
        cName : 'nav-links',
        icone :"fa-solid fa-chart-line" 
    },
    {
        title: "Publicações",
        url: "/",
        cName : 'nav-links',
        icone : "fa-solid fa-bookmark"
    },

    {
        title: "Histórias Inspiradoras",
        url: "/",
        cName : 'nav-links',
        icone : "fa-solid fa-clock-rotate-left"
    },
    {
        title: "Responsabilidade Social",
        url: "/",
        cName : 'nav-links',
        icone:'fa-solid fa-heart'
       
    },
    
    
]

