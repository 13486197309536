import React, {useState} from 'react'
import NavAdmin from '../../composant/NavAdmin'
import SidebarAdmin from '../../composant/sideBarAdmin'
import axios from 'axios';

 

const Cadastrar = () => {
    const [titre, setTitre] = useState([]);
    const [tarif, setTarif] = useState([]);
    const [acao, setAcao] = useState([]);

    const onSubmit = () => {
        let endpoint = "http://localhost/ngsAdvogada/add_menu_consulta.php";
            axios.post(endpoint, {
                titre: titre,
                tarif: tarif,
                acao: acao
            }).then((res) => {
                console.log('File uploaded!');
          
            })
          
          }
  return (
    <>
     <section id="content">
      <NavAdmin/>
      <SidebarAdmin/>
      <main>
    <div class="boxLogin-cadastro">
      <form action="">
        <label htmlFor="" >Titúlo</label>
        <input type="text" name='titre'onChange={e=>setTitre(e.target.value)}/>
        <label htmlFor="">Valor da consulta</label>
        <input type="text" name='tarif' onChange={e=>setTarif(e.target.value)}/>
        <label htmlFor="">Acão</label>
        <select name='acao'onChange={e=>setAcao(e.target.value)}>
            <option value="ativo">ativo</option>
            <option value="desativo">desativo</option>
        </select>
        <button type="submit" onClick={onSubmit}>Cadastar</button>
      </form>
      </div>
      </main>
      </section>
    </>
  )
}

export default Cadastrar
