import React, {useState,useEffect} from 'react'
import NavAdmin from '../../composant/NavAdmin'
import SidebarAdmin from '../../composant/sideBarAdmin'
import { useParams } from 'react-router-dom'
import api from '../../config/configApi'
import axios from 'axios';




const Editar = () => {

  const params = useParams();
  const idcad = useState(params.id);
  const [titre, setTitre] = useState([]);
  const [tarif, setTarif] = useState([]);
  const [acao, setAcao] = useState([]);


  const editTodo = async e => {
    e.preventDefault();  
    const form ={
        id :idcad[0],
        titre: titre,
        tarif:tarif,
        acao: acao
    }
  
    const headers = {
        'headers': {
          'Content-Type': 'application/json'
        }
      }
    await api.post("/editar_menu_consulta.php",form)
    .then((response)=>{
      console.log(form)
        console.log(response.data)
        
    }).catch((err)=>{
        
    })

}

    const getMenuConsulta = async () => {
      await fetch("http://localhost/ngsAdvogada/visualizar_menu_consulta.php?id=" + idcad[0])
          .then((response) => response.json())
          .then((responseJson) => {
              setTitre(responseJson.produto.titre);
              setTarif(responseJson.produto.tarif);
              setAcao(responseJson.produto.acao);
          })
  }
  useEffect(() => {
      
    getMenuConsulta();
  }, []);
 console.log(acao)
  return (
    <>
     <section id="content">
      <NavAdmin/>
      <SidebarAdmin/>
    <main>
    <div class="boxLogin-cadastro">
      <form onSubmit={editTodo}>
        <label htmlFor="" >Titúlo</label>
        <input type="text" name='titre' value={titre} onChange={e=>setTitre(e.target.value)}/>
        <label htmlFor="">Valor da consulta</label>
        <input type="text" name='tarif' value={tarif} onChange={e=>setTarif(e.target.value)}/>
        <label htmlFor="">Acão</label>
        <select name='acao' onChange={e=>setAcao(e.target.value)}>
            <option value="ativo">ativo</option>
            <option value="desativo">desativo</option>
        </select>
        <button type="submit" >Editar</button>
      </form>
      </div>
      </main>
      </section>
    </>
  )
}

export default Editar
