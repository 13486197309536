import React from 'react'
import NavAdmin from '../../composant/NavAdmin'
import SidebarAdmin from '../../composant/sideBarAdmin'
import './style.css'

const Index = () => {
  return (
    <div>
       
      <section id="content">
      <NavAdmin/>
      <SidebarAdmin/>
                                              
		<main>
			<div class="head-title">
				<div class="left">
					<h1>Dashboard</h1>
					<ul class="breadcrumb">
						<li>
							<a href="#">Dashboard</a>
						</li>
						<li><i class='bx bx-chevron-right' ></i></li>
						<li>
							<a class="active" href="#">Home</a>
						</li>
					</ul>
				</div>
				<a href="#" class="btn-download">
					<i class='bx bxs-cloud-download' ></i>
					<span class="text">Download PDF</span>
				</a>
			</div>

			<div class="table-data">
				<div class="order">
					<div class="head">
						<h3>Colaboradores</h3>
						<i class='bx bx-search' ></i>
						<i class='bx bx-filter' ></i>
					</div>
					<table>
						<thead>
							<tr>
								<th>Nome Completo</th>
								<th>Data de Nascimento</th>
								<th>Specialidade</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<img src="img/people.png"/>
									<p>John Doe</p>
								</td>
								<td>01-10-2021</td>
								<td>Direito Ambiental e Tutela de Animais</td>
								<td><span class="status completed">Socio</span></td>
							</tr>
							<tr>
								<td>
									<img src="img/people.png"/>
									<p>John Doe</p>
								</td>
								<td>01-10-2021</td>
								<td>Consulta à Gestante & Mamãe</td>
								<td><span class="status pending">Colaborador</span></td>
							</tr>
							<tr>
								<td>
									<img src="img/people.png"/>
									<p>John Doe</p>
								</td>
								<td>01-10-2021</td>
								<td>Consulta de Direito de Família VIRTUAL</td>
								<td><span class="status process">EStagiário</span></td>
							</tr>
							<tr>
								<td>
									<img src="img/people.png"/>
									<p>John Doe</p>
								</td>
								<td>01-10-2021</td>
								<td>Juizado Violência Doméstica PRESECIAL</td>
								<td><span class="status pending">Secretaria</span></td>
							</tr>
							<tr>
								<td>
									<img src="img/people.png"/>
									<p>John Doe</p>
								</td>
								<td>01-10-2021</td>
								<td>Consulta Jurídica Cível VIRTUAL</td>
								<td><span class="status completed">Financeiro</span></td>
							</tr>
						</tbody>
					</table>
				</div>
				
			</div>
		</main>
		
	</section>
	
    </div>
  )
}

export default Index
