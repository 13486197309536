import React, { useRef, useEffect, useState } from 'react'
import './style.css'
import logo from './../../assets/images/logo_sem_fundo.png'
import api from './../../config/configApi'

const Index = ({toggleModalCadastro}) => {
    const refTel = useRef();
    const [stateInputTelefone, setStateInputTelefone] = useState();
    const [stateInputCPF, setStateInputCPF] = useState();
    const [stateInputRG, setStateInputRG] = useState();
    const [nomeFamiliar, setNomeFamiliar] = useState();
    const [emailFamiliar, setEmailFamiliar] = useState();
    const [estadoCivilFamiliar, setEstadoCivilFamiliar] = useState();
    const [profissaoFamiliar, setProfissaoFamiliar] = useState();
    const [enderecoFamiliar, setEnderecoFamiliar] = useState();
    const [parentescoFamiliar, setParentescoFamiliar] = useState();

    const [stateInputTelefoneCustodiado, setStateInputTelefoneCustodiado] = useState();
    const [stateInputCPFCustodiado, setStateInputCPFCustodiado] = useState();
    const [stateInputRGCustodiado, setStateInputRGCustodiado] = useState();
    const [nomeCustodiado, setNomeCustodiado] = useState();
    const [emailCustodiado, setEmailCustodiado] = useState();
    const [estadoCivilCustodiado, setEstadoCivilCustodiado] = useState();
    const [profissaoCustodiado, setProfissaoCustodiado] = useState();
    const [enderecoCustodiado, setEnderecoCustodiado] = useState();
    const [parentescoCustodiado, setParentescoCustodiado] = useState();

    const [filhos, setFilhos] = useState();
    const [reuPrimario, setReuPrimario] = useState();
    const [dataPrisao, setDataPrisao] = useState();
    const [localPrisao, setLocalPrisao] = useState();
    const [audienciaMarcada, setAudienciaMarcada] = useState();
    const [numProcessoAtual, setNumProcessoAtual] = useState();
    const [numProcessoAnterior, setNumProcessoAnterior] = useState();
    const [advogado, setAdvogado] = useState();


    const valorTelefone = e => {
        let textoAjustado;
        textoAjustado = e.replace(/(\d{2})(\d{5})(\d{4})/,
            function (regex, arg1, arg2, arg3) {
                return arg1 + ' ' + arg2 + ' ' + arg3;
            });
        e = textoAjustado;

        setStateInputTelefone(e)
        console.log(e)

    }
    const valorCPF = e => {

        let CPFAjustado;

        CPFAjustado = e.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
            function (regex, arg1, arg2, arg3, arg4) {
                return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4;
            });
        e = CPFAjustado;

        setStateInputCPF(e)
        console.log(e)

    }

    const valorRG = e => {

        let RGAjustado;

        RGAjustado = e.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/,
            function (regex, arg1, arg2, arg3, arg4) {
                return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4;
            });
        e = RGAjustado;

        setStateInputRG(e)
        console.log(e)

    }
    const valorTelefoneCustodiado = e => {
        let textoAjustado;
        textoAjustado = e.replace(/(\d{2})(\d{5})(\d{4})/,
            function (regex, arg1, arg2, arg3) {
                return arg1 + ' ' + arg2 + ' ' + arg3;
            });
        e = textoAjustado;

        setStateInputTelefoneCustodiado(e)
        console.log(e)

    }
    const valorCPFCustodiado = e => {

        let CPFAjustado;

        CPFAjustado = e.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
            function (regex, arg1, arg2, arg3, arg4) {
                return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4;
            });
        e = CPFAjustado;

        setStateInputCPFCustodiado(e)
        console.log(e)

    }

    const valorRGCustodiado = e => {

        let RGAjustado;

        RGAjustado = e.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/,
            function (regex, arg1, arg2, arg3, arg4) {
                return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4;
            });
        e = RGAjustado;

        setStateInputRGCustodiado(e)
        console.log(e)

    }

    const addTodo = async e => {
        e.preventDefault();
        const form ={
Telefone:stateInputTelefone,
CPF :stateInputCPF,
RG :stateInputRG,
nomeFamiliar :nomeFamiliar,
emailFamiliar : emailFamiliar,
estadoCivilFamiliar : estadoCivilFamiliar,
profissaoFamiliar : profissaoFamiliar,
enderecoFamiliar :enderecoFamiliar, 
parentescoFamiliar : parentescoFamiliar,
TelefoneCustodiado : stateInputTelefoneCustodiado,
CPFCustodiado : stateInputCPFCustodiado,
RGCustodiado : stateInputRGCustodiado,
nomeCustodiado : nomeCustodiado,
emailCustodiado :emailCustodiado, 
estadoCivilCustodiado :estadoCivilCustodiado, 
profissaoCustodiado :profissaoCustodiado, 
enderecoCustodiado : enderecoCustodiado,
filhos : filhos,
reuPrimario : reuPrimario,
dataPrisao : dataPrisao,
localPrisao :localPrisao, 
audienciaMarcada : audienciaMarcada,
numProcessoAtual : numProcessoAtual,
numProcessoAnterior : numProcessoAnterior,
advogado : advogado,
        }
                
            console.log(form) 
               const headers = {
                    'headers': {
                      'Content-Type': 'application/json'
                    }
                  }
                await api.post("/addUser.php",form)
                .then((response)=>{
                    console.log(response.data)
                    
                }).catch((err)=>{
                    
                })
            
    }
    return (
        <>
            <div class="boxLogin-cadastro">
            <button  onClick={toggleModalCadastro} className='close-btn'>x</button>

                <div class="containerLogo">
               
                    <div class="cercle">
                        <img src={logo} alt="logo" />
                    </div>
                </div>

                <div class="containerInputs">

                    <form onSubmit={e => addTodo(e)}>
                        <fieldset>
                            <legend>DADOS DO FAMILIAR DO CUSTODIADO</legend>
                            <label for="prenom" class="labelEmail">Nome Completo</label>
                            <input type="text" class="emailInp" name="nome"   value={nomeFamiliar} 
                            onChange = {e=>setNomeFamiliar(e.target.value)} />
                            <div class="flex2Inputs">

                                <div class="flexLeftInp">
                                    <label for="prenom" class="labelEmail">RG</label>
                                    <input type="text" class="flexInp" name="rg"  maxlength="9" 
                                    placeholder="00.000.000-00" value={stateInputRG} 
                                    onChange={e => valorRG(e.target.value)} />
                                </div>

                                <div className='flexRightInp'>
                                    <label for="prenom" class="labelEmail">CPF</label>
                                    <input type="text" name="cpf"  maxlength="11" 
                                    placeholder="000.000.000-00" class="flexInp" value={stateInputCPF} 
                                    onChange={e => valorCPF(e.target.value)} />
                                </div>
                            </div>

                            <div class="flex2Inputs">
                                <div class="flexLeftInp">
                                    <label for="prenom" class="labelEmail">Profissão</label>
                                    <input type="text" name="profissao"  maxlength="16" value={profissaoFamiliar} 
                                    onChange={e=>setProfissaoFamiliar(e.target.value)} /></div>
                                <div className='flexRightInp'>
                                    <label for="prenom" class="labelEmail">Estado Civil</label>
                                    <input type="text" name="estado"  value={estadoCivilFamiliar} 
                                    onChange={e=>setEstadoCivilFamiliar(e.target.value)}/>
                                </div>
                            </div>
                            <div class="flex2Inputs">
                                <div class="flexLeftInp">
                                    <label for="prenom" class="labelEmail">E-mail</label>
                                    <input type="email" name="email"   value={emailFamiliar} 
                                    onChange={e=>setEmailFamiliar(e.target.value)}/>
                                </div>
                                <div class="flexRightInp">
                                    <label for="prenom" class="labelEmail">Telefone/WhatsApp</label>
                                    <input type="text" id='num' name="telefone"  maxlength="11"
                                    placeholder="00 00000 0000" pattern="[0-9\s]+" value={stateInputTelefone} 
                                    onChange={e => valorTelefone(e.target.value)} />
                                </div>
                            </div>

                            <label for="prenom" class="labelEmail">Endereço Completo</label>
                            <input type="text" name="endereco"   value={enderecoFamiliar} 
                            onChange={e=>setEnderecoFamiliar(e.target.value)} />

                            <label for="prenom" class="labelEmail">Parentesco com o Custodiado </label>
                            <input type="text" name="parentesco"  maxlength="16" value={parentescoFamiliar}
                             onChange={e=>setParentescoFamiliar(e.target.value)} />


                        </fieldset>

                        <fieldset>
                            <legend>DADOS DO CUSTODIADO</legend>
                            <label for="prenom" class="labelEmail">Nome Completo</label>
                            <input type="text" class="emailInp" name="nome"   value={nomeCustodiado} 
                            onChange = {e=>setNomeCustodiado(e.target.value)} />
                            <div class="flex2Inputs">

                                <div class="flexLeftInp">
                                    <label for="prenom" class="labelEmail">RG</label>
                                    <input type="text" class="flexInp" name="rg"  maxlength="9" 
                                    placeholder="00.000.000-00" value={stateInputRGCustodiado} 
                                    onChange={e => valorRGCustodiado(e.target.value)} />
                                </div>

                                <div className='flexRightInp'>
                                    <label for="prenom" class="labelEmail">CPF</label>
                                    <input type="text" name="cpf"  maxlength="11" 
                                    placeholder="000.000.000-00" class="flexInp" value={stateInputCPFCustodiado} 
                                    onChange={e => valorCPFCustodiado(e.target.value)} />
                                </div>
                            </div>

                            <div class="flex2Inputs">
                                <div class="flexLeftInp">
                                    <label for="prenom" class="labelEmail">Profissão</label>
                                    <input type="text" name="profissao"  maxlength="16" 
                                    value={profissaoCustodiado} 
                                    onChange={e=>setProfissaoCustodiado(e.target.value)} /></div>
                                <div className='flexRightInp'>
                                    <label for="prenom" class="labelEmail">Estado Civil</label>
                                    <input type="text" name="estado"  
                                    value={estadoCivilCustodiado} 
                                    onChange={e=>setEstadoCivilCustodiado(e.target.value)}/>
                                </div>
                            </div>
                            <div class="flex2Inputs">
                                <div class="flexLeftInp">
                                    <label for="prenom" class="labelEmail">E-mail</label>
                                    <input type="email" name="email"   value={emailCustodiado} 
                                    onChange={e=>setEmailCustodiado(e.target.value)}/>
                                </div>
                                <div class="flexRightInp">
                                    <label for="prenom" class="labelEmail">Telefone/WhatsApp</label>
                                    <input type="text" id='num' name="telefone"  maxlength="11"
                                    placeholder="00 00000 0000" pattern="[0-9\s]+" value={stateInputTelefoneCustodiado} 
                                    onChange={e => valorTelefoneCustodiado(e.target.value)} />
                                </div>
                            </div>

                            <label for="prenom" class="labelEmail">Endereço Completo</label>
                            <input type="text" name="endereco"   
                            value={enderecoCustodiado} 
                            onChange={e=>setEnderecoCustodiado(e.target.value)} />

                            <div class="flex2Inputs">
                                <div class="flexLeftInp">
                                    <label for="prenom" class="labelEmail">Tem filhos? / Quantos? </label>
                                    <input type="number" name="filhos"  maxlength="16"
                                    value={filhos} 
                                    onChange={e=>setFilhos(e.target.value)} />
                                </div>
                                <div class="flexRightInp">
                                    <label for="inpSelect" class="labelSelect">É casado ou vive em união estável?</label>
                                    <select name="select" id="inpSelect" 
                                    onChange={e=>setEstadoCivilCustodiado(e.target.value)}>
                                        <option value="">Select...</option>
                                        <option value="casada"> casado(a)</option>
                                        <option value="união estavel">união Estavel</option>
                                    </select>
                                </div>
                            </div>

                            <label for="inpSelect" class="labelSelect">Réu Primário</label>
                            <select name="select" id="inpSelect" onChange={e=>setReuPrimario(e.target.value)}>
                                <option value="">Select...</option>
                                <option value="sim "> Sim</option>
                                <option value="Não">Não</option>
                            </select>
                            <div class="flex2Inputs">
                                <div class="flexLeftInp">
                                    <label for="prenom" class="labelEmail">Data da Prisão</label>
                                    <input type="date" name="dataPrisao"  maxlength="16"  
                            onChange={e=>setDataPrisao(e.target.value)}/>
                                </div>
                                <div class="flexRightInp">
                                    <label for="inpSelect" class="labelSelect">Local da Prisão</label>
                                    <input type="text" name="locaPrisao"  maxlength="16" 
                                     onChange={e=>setLocalPrisao(e.target.value)}/>
                                </div>
                            </div>
                            <label for="inpSelect" class="labelSelect">Audiência marcada?</label>
                            <select name="select" id="inpSelect" onChange={e=>setAudienciaMarcada(e.target.value)}>
                                <option value="">Select...</option>
                                <option value="sim "> Sim</option>
                                <option value="Não">Não</option>
                            </select>

                            <div class="flex2Inputs">
                                <div class="flexLeftInp">
                                    <label for="prenom" class="labelEmail">Número do processo atual </label>
                                    <input type="text" name="numProcesso"  maxlength="16"
                                     value={numProcessoAtual}
                                     onChange={e=>setNumProcessoAtual(e.target.value)} />
                                </div>
                                <div class="flexRightInp">
                                    <label for="prenom" class="labelEmail">Números de processos anteriores </label>
                                    <input type="text" name="numProcesso"  maxlength="16" 
                                    value={numProcessoAnterior} 
                                    onChange={e=>setNumProcessoAnterior(e.target.value)} />
                                </div>
                            </div>

                            <label for="inpSelect" class="labelSelect">Possui advogado particular ou está representado pela Defensoria Pública atualmente?</label>
                            <select name="select" id="inpSelect" onChange={e=>setAdvogado(e.target.value)} >
                                <option value="">Select...</option>
                                <option value="sim "> Advogado Particular</option>
                                <option value="Não">Defensoria Pública</option>
                            </select>
                        </fieldset>

                        <button className='btn-cadastrar' type='submit'>Cadastrar</button>
                    </form>

                    <p class="txtFin">Já  cadastrado ?
                        <a href="./login">
                            Entrar
                        </a>
                    </p>
                </div>

            </div>

        </>
    )
}

export default Index
