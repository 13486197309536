import React, {useState,useEffect,useRef} from 'react'
import './styles.css'
import Calendar from './../../composant/DatePicker'
import MenuNav from './../../composant/NavBarAdmin'



const Index = () => {
	
  const [menuConsulta, setMenuConsulta] = useState([]);
	const myRef = useRef();
	const UrlBase = ' https://ngs.adv.br/';
	const getMenuConsulta = async () => {
		fetch(UrlBase + 'menu_consulta.php')
			.then(response => response.json())
			.then(data => {
				console.log(data.length)

				setMenuConsulta(data);
			})

	}

useEffect(() => {
		getMenuConsulta();

	}, []);
  return (
    <>
     <section id="content-home">
        <MenuNav />
      </section>
      <div className="container-sobre">
    <div className='carte-sobre'>
      <h1>Nathalie Guimarães, advogada criminalista, membro da Comissão de Direitos Humanos da OAB, ativista pela saúde mental da família encarcerada.</h1>
	  <p>
	  Eu, sou advogada desde 2014, e me dedico aos estudos não Direito desde a faculdade o primeiro ano de graduação como acadêmica e estagiária.
	  </p>
	  <p>

Sou atuante na área penal desde 2015.
	  </p>
	  <p> Também tenho longa experiência em Direito das Famílias e Direito Civil, porém a minha área de atual dedicação exclusiva é Direito Penal e Processo Penal.</p>
    <p>Me dedico às causas penais e meu foco também está no acolhimento da família que muitas vezes acaba por ser também penalizada em virtude do encarceramento do seu ente familiar.</p>
	<p>

Não há causas perdidas e não há cliente que “não tem mais jeito”.</p><p>

Não costumo gravar ou saber os apelidos dos meus clientes, pois pra mim são pessoas, cidadãos em situação passageira de restrição de liberdade.</p><p>

Eu sou advogada que acredita na ressocialização do custodiado penal, e tem certeza que o advogado deve exercer papel ativo nesse cenario social.</p><p>

Cada cliente uma nova história de um ser humano que vem carregado de dor, abandono estatal, rupturas e desestruturas familiares e psicológicas antes, durante e depois do cárcere.</p><p>

Historias que não justificam mas tentam explicar um fato social : “o encarceramento massivo de pobres e periféricos”.</p><p>

Há outros perfis em recorte, e eu sou a advogada que transita entre todos.</p><p>

Sou mulher, afro indígena e advogada.</p><p>

Muito Prazer, sou Nathalie Guimarães dos Santos.</p>
</div>
	
	</div>
    </>
  )
}

export default Index