import React from 'react'


import MenuNav from './../../composant/NavBarAdmin'
import Blog from './../Blog/Blog'
import { useEffect, useState, useRef } from "react"
import CodePix from './../../composant/CodePix/'
import axios from 'axios';
import nathalie from './../../assets/images/nathalie_capa3.jpeg'
import { useNavigate } from 'react-router-dom';
import './home.css';

import Card from './../../composant/Card/Card';
import{useSelector,useDispatch} from 'react-redux'
import {getArticles}from './../../redux/articles/articleReducer'
import {v4 as uuidv4} from 'uuid'
import {Link} from 'react-router-dom'

const Index = () => {

  const {articles}= useSelector(state=>({
    ...state.articleReducer
  }))
  const dispatch = useDispatch()
  const [menuConsulta, setMenuConsulta] = useState([]);
  const navigate = useNavigate();
  const goSobre= () => { navigate("/sobre") }
	const myRef = useRef();
	const UrlBase = ' https://ngs.adv.br/';
	const getMenuConsulta = async () => {
		fetch(UrlBase + 'menu_consulta_ativo.php')
			.then(response => response.json())
			.then(data => {
				console.log(data.length)

				setMenuConsulta(data);
			})

	}

	useEffect(() => {
		getMenuConsulta();
    if(articles.length===0){
      dispatch(getArticles());
    }

	}, []);
 
  const [nome, setNome] = useState([]);
    const [email, setEmail] = useState([]);
    const [telefone, setTelefone] = useState([]);
    const [motivo, setMotivo] = useState([]);
    const [mensagem, setMensagem] = useState([]);
    const onSubmit = (e) => {
      e.preventDefault();
        let endpoint = "https://ngs.adv.br/add_contato.php";
            axios.post(endpoint, {
                nome: nome,
                email: email,
                telefone: telefone,
                motivo:motivo,
                mensagem:mensagem
            }).then((res) => {
                console.log('File uploaded!');
          
            })
          
          }

  return (
    <>
      <section id="content-home">
        <MenuNav />
      </section>

      <div className='capa-home'>
       <div>
        <h1>Dra Nathalie Guimarães dos Santos</h1>
        <p class="sous-titre">Advogada e consultora juridica</p>
        </div>
        <section class="section-tarifs" id="tarifs">

          <div class="container-tarifs">
          
            <div class="cartes-tarifs">
              <h4>Digital e Presencial</h4>
              <span>Atendemos presencialmente e ou à distância, sempre de sigilosa</span>
             
            </div>
            <div class="cartes-tarifs">
              <h4>Agilidade</h4>
              <span>Temos um tempo ágil de resposta, voltado em apresentar soluções eficazes para nossos clientes.</span>
             
            </div>

            <div class="cartes-tarifs">
              <h4>Experiência</h4>
              <span>Nosso time é  repleto de especialistas dentro do Direito criminal, prestando um serviço de excelência.</span>
            </div>

            <div class="cartes-tarifs">
              <h4>Especialização</h4>
              <span>Somos um escritório especializado com profissionais extremamente capacitadas e com larga experiência.</span>
            </div>


          </div>

        </section>


      </div>
     
      <div className="bloc-sobre">
      <div className='image-nat'>
            <img src= {nathalie} alt="couloir d'un temple de kyoto"/>
            </div>
            <div class='content'>
                <h3> Dra. Nathalie Guimarães <br />
                OAB/SP 367.268 </h3>
                <p>
                Sou Nathalie Guimarães, advogada criminalista com 10 anos de experiência na área do Direito e Processo Penal, atuo em Inquérito Policial, Flagrantes em Delegacia, Revogação de Prisão Preventiva, Prisão Temporária, Liberdade Provisória, Habeas Corpus, Tribunal do Júri, Defesa de em Ações Penais contra Pessoas Físicas e Jurídicas, na fase de Execução Criminal, atuo com defesas de processo disciplinar em aplicação de faltas médias e graves, pedido de Progressão de Regime Semi Aberto, Regime Aberto, Liberdade Condicional, Revisão Criminal, Recambiamento de presos entre outros Estados do país e Aproximação Familiar.<br />
Também atuo com Apelação Criminal, Recurso Especial, Habeas Corpus e todos os Recursos admitidos no Superior Tribunal de Justiça!
                </p>
                
                <div className='icons-container'>
                <a href='https://m.facebook.com/ngs.advogada.consultora?mibextid=ZbWKwL'>
                  <div className='icons'><i class="fa-brands fa-facebook"></i>
                  <span>FaceBook</span></div></a>
                  <a href="https://wa.me/message/IHVR2VYR6OHLJ1">
                <div className='icons'>  <i class="fa-brands fa-whatsapp"></i>
                <span>whatsApp</span></div></a>
                <a href="https://instagram.com/ngs.advogada?igshid=NGExMmI2YTkyZg==">
                <div className='icons'> <i class="fa-brands fa-instagram"></i>
                <span>Instagram</span></div></a>
                
                </div>
                <button class="btn-bloc-left" onClick={goSobre} >Saiba mais</button>
            </div>
            
        </div>
        <h1 className="home-title">Todas publicações</h1>
        <div className="container-cards">
                  
            {articles.map(item=>{
              return (
                <Card key={uuidv4()}>

                  <h2>{item.titre}</h2>
                  <Link 

                      to={
                        `article/${item.titre.trim().replace(/\s+/g, '-')}`
                      }
                      state={{
                        title: item.titre,
                        body: item.ingredients
                      }}

                       >Ler a receita</Link>
                </Card>
              )
            })}
            
        </div>
      <Blog/>

      <section class="parallax">


<h2>ADVOGADA CRIMINALISTA PARTICULAR - 24 HORAS</h2>
<span>
Ofereço serviços jurídicos como advogada particular, dedicando-me a atender cada cliente de forma personalizada e com excelência. Meu compromisso é fornecer soluções jurídicas eficazes e adaptadas às suas necessidades específicas.
</span>
        <button class="btn-bloc-left" >Entre em contato </button>
      </section>

      <section class="section-contact" id="contact">

        <h2><strong>Entre </strong> em Contato</h2>

        <div class="container-form">

          <form class="form-bloc">

            <div class="form-groupe">
              <label for="prenom">Nome completo</label>
              <input type="text" placeholder="sobrenome" required id="prenom" name='nome' onChange={e=>setNome(e.target.value)} />
            </div>

            <div class="form-groupe">
              <label for="nom">E_mail</label>
              <input type="email"  required id="nom" name='email' onChange={e=>setEmail(e.target.value)}/>
            </div>
            <div class="form-groupe">
              <label for="nom">Telefone para contato</label>
              <input type="number"  required id="nom"name='telefone' onChange={e=>setTelefone(e.target.value)} />
            </div>
            <div class="form-groupe">
              <label for="nom">Motivo do Contato</label>
              <input type="text"  required id="nom"name='motivo'  onChange={e=>setMotivo(e.target.value)}/>
            </div>

            <div class="form-groupe">
            <label for="nom">Mensagem</label>
              <textarea id="nom" name='mensagem' required onChange={e=>setMensagem(e.target.value)}></textarea>
            </div>

            <div class="form-groupe">
              <input type="submit" onClick={onSubmit} value="ENVIAR" class="button-sub" />
            </div>

          </form>

        </div>

      </section>

      <footer>
        Todos os direitos reservados &copy;
      </footer>
    </>
  )
}

export default Index
